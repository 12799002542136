import config from '../config/clients.json';
import Request from '../services/request';

class ServerClient {
  constructor() {
    this.request = new Request(config.url.server);
  }

  login = (login_id, password) => {
    return this.request.post(
      'users/login',
      {
        device_id: '',
        login_id: login_id,
        password: password,
        token: null,
      },
      null,
      'me',
    );
    // .then(resp => {
    //     const ok = resp.data && resp.data.roles && resp.data.roles.match(/(admin|tradersclub)/g);

    //     if(ok) {
    //         return resp.
    //         // this.request.setToken(resp.headers.token);
    //         // window.location = '#/';
    //     } else {
    //         this.logout();
    //     }
    // });
  };

  logout = () => {
    // this.request.setToken("");
    return this.request.post('users/logout');
  };

  initMe = () => {
    return this.loadMe().then(resp => {
      const ok =
        resp.data &&
        resp.data.roles &&
        resp.data.roles.match(/(admin|journalist|system_collab|support|tradersclub)/g);

      if (ok) {
        if (window.location.hash === '#/login') window.location = '#/';
      } else {
        this.logout();
      }
    });
  };

  loadMe = () => {
    return this.request.get('users/me', null, null, 'me');
  };

  uploadFile = file => {
    let data = new FormData();
    data.append('files', file);
    data.append('channel_id', 'ws4ejqpfo7nm9pm39kutpkq97w');
    return this.request.post('files', data);
  };

  upRoles = (id, data) => {
    return this.request.put(`users/${id}/roles`, data);
  };

  updateRolesChannelMembers = (channelId, userId, data) => {
    return this.request.put(`channels/${channelId}/members/${userId}/roles`, data);
  };

  _opengraph = url => {
    return this.request.post('opengraph', { url: url });
  };

  getUser = users => {
    return this.request.post(`/users/ids`, users);
  };

  _linkAssetToUser = (userId, body) => {
    return this.request.post(`/users/${userId}/company`, body);
  };

  updateUser = data => {
    let body = {
      id: data.Id,
      delete_at: data.DeleteAt,
      username: data.Username,
      email: data.Email,
      first_name: data.FirstName,
      last_name: data.LastName,
      position: data.Position,
      roles: data.Roles,
      allow_marketing: data.AllowMarketing,
      props: data.Props,
      failed_attempts: data.FailedAttempts,
      bio: data.Bio,
      badge: data.Badge,
    };

    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    return this.request.put(`users/${body.id}/patch`, body);
  };
}

export default new ServerClient();
