import produce from 'immer';
const INITIAL_STATE = {
  token: null,
  saveLogin: { username: '', password: '' },
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token;
        break;
      }
      case '@auth/SIGN_IN_SAVEDATA': {
        draft.saveLogin = action.payload;
        break;
      }
      case '@auth/SIGN_OUT': {
        if (state.saveLogin) {
          draft.token = INITIAL_STATE.token;
          draft.saveLogin = state.saveLogin;
        } else {
          draft = INITIAL_STATE;
        }

        break;
      }

      case '@auth/SIGN_OUT_REQUEST': {
        console.log('entrou aqui hahah >>');
        if (state.saveLogin) {
          draft.token = INITIAL_STATE.token;
          draft.saveLogin = state.saveLogin;
        } else {
          draft = INITIAL_STATE;
        }

        break;
      }
      default:
    }
  });
}
