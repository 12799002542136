const stack = {};
class EventEmitter {
  emit = (event, ...params) => {
    if (stack[event] && stack[event].length) {
      stack[event].forEach(fn => fn && fn(...params));
    }
  };

  off = (event, fn) => {
    if (stack[event] && stack[event].length) {
      stack[event] = stack[event].filter(fn1 => fn1 !== fn);
    }
  };

  on = (event, fn) => {
    if (!stack[event]) stack[event] = [];
    stack[event].push(fn);
  };
}

export default new EventEmitter();
