import { toast } from 'react-toastify';
import { takeLatest, put, all } from 'redux-saga/effects';

import MoverNews from '../../../clients/mover';
import ClientServer from '../../../clients/server';
import { store } from '../../../store';
import {
  postReceivedSuccess,
  postsReceivedFailure,
  highlightsReceivedFailure,
  highlightsReceivedSuccess,
  postDraftSuccess,
  postsDraftFailure,
} from '../posts/actions';

export function* PostRequest({ payload }) {
  const { page, limit } = payload;

  try {
    const mover = new MoverNews();
    const resp = yield mover._getPosts(page, limit);
    let user = [];

    let user_base = store.getState().posts.users;

    yield Object.values(resp.data.posts).map(post => user.push(post.user_id));

    let users_id = user.filter((este, i) => user.indexOf(este) === i && este !== '0');

    user_base.map((i, index) => {
      if (users_id.includes(i.id)) {
        users_id.splice(users_id.indexOf(i.id), 1);
      }
      return i;
    });

    let users = { data: user_base };
    if (users_id.length > 0) {
      users = yield ClientServer.getUser(users_id);
    }

    yield put(
      postReceivedSuccess({
        data: resp.data,
        page: page,
        users: [...user_base, ...users.data],
      }),
    );
  } catch (error) {
    toast.error('Error ao Carregar Post');

    yield put(postsReceivedFailure(error));
  }
}

export function* PostDrafRequest({ payload }) {
  const { page, limit } = payload;

  try {
    const mover = new MoverNews();
    const resp = yield mover._getPostsDraft(page, limit);
    let user = [];

    let user_base = store.getState().posts.users;

    yield Object.values(resp.data.posts).map(post => user.push(post.user_id));

    let users_id = user.filter((este, i) => user.indexOf(este) === i && este !== '0');

    user_base.map((i, index) => {
      if (users_id.includes(i.id)) {
        users_id.splice(users_id.indexOf(i.id), 1);
      }
      return i;
    });

    let users = { data: user_base };
    if (users_id.length > 0) {
      users = yield ClientServer.getUser(users_id);
    }

    yield put(
      postDraftSuccess({
        data: resp.data,
        page: page,
        users: [...user_base, ...users.data],
      }),
    );
  } catch (error) {
    toast.error('Error ao carregar rascunhos');

    yield put(postsDraftFailure(error));
  }
}

export function* HighlightsRequest() {
  try {
    const mover = new MoverNews();
    const resp = yield mover._getTopMovers();
    let user = [];

    let user_base = store.getState().posts.users;

    yield Object.values(resp.data.posts).map(post => user.push(post.user_id));

    let users_id = user.filter((este, i) => user.indexOf(este) === i && este !== '0');

    user_base.map((i, index) => {
      if (users_id.includes(i.id)) {
        users_id.splice(users_id.indexOf(i.id), 1);
      }
      return i;
    });

    let users = { data: user_base };
    if (users_id.length > 0) {
      users = yield ClientServer.getUser(users_id);
    }

    yield put(
      highlightsReceivedSuccess({
        data: resp.data,
        users: [...user_base, ...users.data],
      }),
    );
  } catch (error) {
    toast.error('Error ao Carregar Post');

    yield put(highlightsReceivedFailure());
  }
}

export default all([
  takeLatest('@posts/NEWS_RECEIVED_POSTS_REQUEST', PostRequest),
  takeLatest('@posts/NEWS_DRAFT_POSTS_REQUEST', PostDrafRequest),
  takeLatest('@highlights/NEWS_RECEIVED_HIGHLIGHTS_REQUEST', HighlightsRequest),
]);
