import { store } from '../store';

export const isAuthenticated = () => {
  if (store.getState().auth.token === null || store.getState().user.profile === null) {
    return false;
  } else {
    return true;
  }
};

export function generateId() {
  // implementation taken from http://stackoverflow.com/a/2117523
  var id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

  id = id.replace(/[xy]/g, c => {
    var r = Math.floor(Math.random() * 16);

    var v;
    if (c === 'x') {
      v = r;
    } else {
      v = (r & 0x3) | 0x8;
    }

    return v.toString(16);
  });

  return id;
}

export function signalLogout() {
  // if (this.isLocalStorageSupported()) {
  // PLT-1285 store an identifier in session storage so we can catch if the logout came from this tab on IE11
  const logoutId = generateId();

  sessionStorage.setItem('__logout_admin__', logoutId);
  localStorage.setItem('__logout_admin__', logoutId);
  localStorage.removeItem('__logout_admin__');
  window.location.href = '#/login';
  // }
}

export function signalLogin() {
  // if (this.isLocalStorageSupported()) {
  // PLT-1285 store an identifier in session storage so we can catch if the logout came from this tab on IE11
  const logoutId = generateId();

  sessionStorage.setItem('__login_admin__', logoutId);
  localStorage.setItem('__login_admin__', logoutId);
  localStorage.removeItem('__login_admin__');
  window.location.href = '#/login';
  // }
}

export function isSignallingLogout(logoutId) {
  return logoutId === sessionStorage.getItem('__logout_admin__');
}

export function isSignallingLogin(logoutId) {
  return logoutId === sessionStorage.getItem('__login_admin__');
}
