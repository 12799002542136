export function stockBufferSuccess(data, type) {
  return {
    type: '@stockbuffer/STOCKBUFFER_SUCCESS',
    payload: { data, type },
  };
}

export function getAllCurrencies() {
  return {
    type: '@stockbuffer/MATRIX_WALLET_GET_CURRENCIES',
  };
}

export function putAllCurrencies(data) {
  return {
    type: '@stockbuffer/MATRIX_WALLET_PUT_CURRENCIES',
    payload: { data },
  };
}

export function getBenchmarks() {
  return {
    type: '@stockbuffer/MATRIX_WALLET_GET_BENCHMARK',
  };
}

export function putBenchmarks(data) {
  return {
    type: '@stockbuffer/MATRIX_WALLET_PUT_BENCHMARK',
    payload: { data },
  };
}

export function getExceptionalAssets() {
  return {
    type: '@stockbuffer/MATRIX_WALLET_GET_EXCEPTIONAL_ASSETS',
  };
}

export function putExceptionalAssets(data) {
  return {
    type: '@stockbuffer/MATRIX_WALLET_PUT_EXCEPTIONAL_ASSETS',
    payload: { data },
  };
}
