import { takeLatest, put, all } from 'redux-saga/effects';

import { cashtagsSuccess, cashtagsFailure } from './actions';

import cashtagsClient from '../../../clients/cashtags';

export function* cashtags() {
  try {
    const cashtag = new cashtagsClient();
    const resp = yield cashtag.getCashtags();
    let data = [];
    if (resp && resp.data && resp.data.data) {
      data = resp.data.data.map(cashtag => ({
        id: cashtag.Id,
        name: cashtag.Stock,
      }));
    }

    yield put(cashtagsSuccess(data));
  } catch (error) {
    put(cashtagsFailure());
  }
}

export default all([
  takeLatest('@cashtags/CASHTAGS_REQUEST', cashtags),
]);
