import produce from 'immer';

const INITIAL_STATE = {
  stockBuffer: [],
  currency: {
    loading: false,
    currencies: [],
  },
  benchmark: {
    loading: false,
    benchmarks: [],
  },
  exceptionalAsset: {
    loading: false,
    exceptionalAssets: [],
  },
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@stockbuffer/STOCKBUFFER_SUCCESS': {
        if (action.payload.type === 'add') {
          const stocksCopy = [...state.stockBuffer];
          stocksCopy.push(action.payload.data);
          draft.stockBuffer = stocksCopy;
        } else if (action.payload.type === 'remove') {
          const stocksCopy = [...state.stockBuffer];
          stocksCopy.splice(action.payload.data, 1);
          draft.stockBuffer = stocksCopy;
        } else if (action.payload.type === 'initial') {
          draft.stockBuffer = [];
        }

        break;
      }
      case '@stockbuffer/MATRIX_WALLET_GET_CURRENCIES':
        draft.currency = { ...INITIAL_STATE.currency, loading: true };
        break;
      case '@stockbuffer/MATRIX_WALLET_PUT_CURRENCIES':
        draft.currency.currencies = action.payload.data;
        draft.currency.loading = false;
        break;
      case '@stockbuffer/MATRIX_WALLET_GET_BENCHMARK':
        draft.benchmark = { ...INITIAL_STATE.benchmark, loading: true };
        break;
      case '@stockbuffer/MATRIX_WALLET_PUT_BENCHMARK':
        draft.benchmark.benchmarks = action.payload.data;
        draft.benchmark.loading = false;
        break;
      case '@stockbuffer/MATRIX_WALLET_GET_EXCEPTIONAL_ASSETS':
        draft.exceptionalAsset = { ...INITIAL_STATE.exceptionalAsset, loading: true };
        break;
      case '@stockbuffer/MATRIX_WALLET_PUT_EXCEPTIONAL_ASSETS':
        draft.exceptionalAsset.exceptionalAssets = action.payload.data;
        draft.exceptionalAsset.loading = false;
        break;
      case '@auth/SIGN_OUT': {
        draft = INITIAL_STATE;
        break;
      }
      case '@auth/SIGN_OUT_REQUEST': {
        draft = INITIAL_STATE;
        break;
      }
      default:
    }
  });
}
