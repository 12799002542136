import { takeLatest, put, all } from 'redux-saga/effects';

import { hashtagsSuccess, hashtagsFailure } from './actions';

import moverNews from '../../../clients/mover';

export function* hashtags() {
  try {
    const MoverClient = new moverNews();
    const resp = yield MoverClient._getHashtags();
    let data = [];
    if (resp && resp.data) {
      data = resp.data.map(item => {
        item.name = item.name.toUpperCase();
        item.selected = false;
        return item;
      });
    }

    yield put(hashtagsSuccess(data));
  } catch (error) {
    put(hashtagsFailure());
  }
}

export default all([
  takeLatest('@hashtags/HASHTAGS_REQUEST', hashtags),
]);
