import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import cashtags from './cashtags/sagas';
import hashtags from './hashtags/sagas';
import matrixWallet from './matrixWallet/saga';
import posts from './posts/sagas';
import user from './user/sagas';

export default function* rootSaga() {
  return yield all([auth, user, posts, hashtags, cashtags, matrixWallet]);
}
