export default {
  url: {
    mover: '/news/api',
    payment: '/payment/api',
    server: '/api/v4',
    node: '/tc/v1',
    mining: '/mining',
    research: '/tcresearchs/api',
    matrixWallet: '/matrixwallet/v1',
  },
};
