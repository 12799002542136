import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import { takeLatest, put, all } from 'redux-saga/effects';

import server from '../../../clients/server';
import config from '../../../config/clients.json';
import { signalLogout, signalLogin } from '../../../utils/validAuth';
import { persistor } from '../../index';
import {
  signInSuccess,
  signFailure,
  signInSaveData,
  // signOutRequest as signOutRequestAction,
} from '../auth/actions';

export function* signIn({ payload }) {
  const { login_id, password, saveLogin } = payload;

  try {
    const resp = yield server.login(login_id, password);

    const permited =
      resp.data &&
      resp.data.roles &&
      resp.data.roles.match(/(system_admin|journalist|system_collab|support|tradersclub)/g);

    if (permited) {
      yield put(signInSuccess(resp.headers.token, resp.data));

      if (saveLogin) {
        const passwordEncrypt = CryptoJS.AES.encrypt(password, config.Key_encrypt).toString();

        yield put(signInSaveData(login_id, passwordEncrypt));
      }

      signalLogin();
      window.location = '#/';
    } else {
      toast.error('Seu usuário não possui permissão para essa aplicação.');
      put(signFailure());
      server.logout();
      setTimeout(() => {
        window.location.href = 'https://tc.tradersclub.com.br/';
      }, 1000);
    }
  } catch (error) {
    toast.error('Falha no login, verifique seus dados.');
    console.log('error_signIn: ', JSON.stringify(error));
  }
}

export async function signOut() {
  try {
    await server.logout();
    await persistor.flush();
    signalLogout();
  } catch (error) {
    console.log('error_signOut:', error);
  }
}

export async function signOutRequest() {
  try {
    console.log('entrou aqui >>>');
    // put(signOutRequestAction());
    await persistor.flush();
    signalLogout();
  } catch (error) {
    console.log('error_signOut:', error);
  }
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('@auth/SIGN_OUT_REQUEST', signOutRequest),
]);
