import produce from 'immer';

const INITIAL_STATE = {
  cashtags: [],
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@cashtags/CASHTAGS_SUCCESS': {
        draft.cashtags = action.payload.data;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft = INITIAL_STATE;
        break;
      }
      case '@auth/SIGN_OUT_REQUEST': {
        draft = INITIAL_STATE;
        break;
      }
      default:
    }
  });
}
