import produce from 'immer';

const INITIAL_STATE = {
  hashtags: [],
  hashtagsWithoutCTC: [],
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@hashtags/HASHTAGS_SUCCESS': {
        draft.hashtags = action.payload.data;

        let withoutCTC = action.payload.data.filter(item => item.abbreviations !== 'ctc');
        draft.hashtagsWithoutCTC = withoutCTC;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft = INITIAL_STATE;
        break;
      }

      case '@auth/SIGN_OUT_REQUEST': {
        draft = INITIAL_STATE;
        break;
      }
      default:
    }
  });
}
