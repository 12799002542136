import config from '../config/clients';
import Request from '../services/request';

class MoverClient {
  constructor() {
    this.request = new Request(config.url.mover);
  }

  _createPost = data => {
    return this.request.post('posts', data);
  };

  _createPostMining = data => {
    return this.request.post('/posts/mining', data);
  };

  _getPosts = (page = 0, limit = 30) => {
    return this.request.get(`posts?page=${page}&limit=${limit}`);
  };

  _getPostsDraft = (page = 0, limit = 30) => {
    return this.request.get(`posts/draft?page=${page}&limit=${limit}`);
  };

  _getPost = post_id => {
    return this.request.get(`posts/${post_id}`);
  };

  _updatePost = data => {
    return this.request.put('posts', data);
  };

  _deletePost = id => {
    return this.request.delete(`posts/${id}`);
  };

  getHashFilePublic = id => {
    return this.request.get(`files/${id}/public`);
  };

  _getHashtags = () => {
    return this.request.get(`/hashtags`);
  };

  _getUsersToPost = () => {
    return this.request.get(`posts/updateusersmining`);
  };

  _getUsersToPostNotMe = () => {
    return this.request.get(`posts/usersmining`);
  };

  _updateUsersToPost = data => {
    return this.request.put(`posts/usersmining`, data);
  };

  _getTopMovers = () => {
    return this.request.get(`analytics/top`);
  };

  _setHighlight = data => {
    return this.request.post(`analytics/editor`, data);
  };

  _getCompanies = () => {
    return this.request.get(`user/companies`);
  };
  _setCompany = body => {
    return this.request.post(`user/companies`, body);
  };
  _updateCompany = body => {
    return this.request.put(`user/companies`, body);
  };

  _deleteCompany = body => {
    return this.request.delete(`user/companies`, body);
  };

  _getCompanyByUserId = UserId => {
    return this.request.get(`user/company/${UserId}`);
  };

  _getTemplates = () => {
    return this.request.get(`/templates`);
  };

  _getTemplate = id => {
    return this.request.get(`/templates/${id}`);
  };

  _postTemplate = body => {
    return this.request.post(`/templates`, body);
  };

  _updateTemplate = body => {
    return this.request.put(`/templates`, body);
  };

  _deleteTemplate = id => {
    return this.request.delete(`/templates/${id}`);
  };
  _createPostMultiplo = body => {
    return this.request.post(`/posts/multiplo`, body);
  };

  _setMetric = body => {
    return this.request.post(`/analytics/metric`, body);
  };
}

export default MoverClient;
