import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import db from '../db';
import { store } from '../store';
import { signOutRequest } from '../store/modules/auth/actions';

class Request {
  base_url: string = '';

  constructor(base_url: string) {
    if (base_url && !base_url.includes('http')) {
      base_url = window.location.origin + base_url;
    }

    if (base_url) {
      this.base_url =
        base_url[base_url.length - 1] === '/'
          ? base_url.substring(0, base_url.length - 2)
          : base_url;
    }
  }

  getBaseUrl = (): string => {
    return this.base_url;
  };

  setToken = (token: string) => {
    localStorage.setItem('token', token);
  };

  getToken = (): string | null => {
    return localStorage.getItem('token');
  };

  makeUrl = (url?: string): string => {
    if (!url) return this.getBaseUrl();

    url = url[0] === '/' ? url : '/' + url;
    return this.getBaseUrl() + url;
  };

  makeHeaders = (opts: AxiosRequestConfig['headers']) => {
    let header: AxiosRequestConfig['headers'] = {};
    let token = store.getState().auth.token;
    if (token) header = { Authorization: `BEARER ${token}` };
    if (opts) header = { ...header, ...opts };
    return header;
  };

  makeData = (data?: AxiosRequestConfig['data']) => {
    return data || {};
  };

  request = <T>(
    method: AxiosRequestConfig['method'] = 'GET',
    url?: AxiosRequestConfig['url'],
    data?: AxiosRequestConfig['data'],
    headers?: AxiosRequestConfig['headers'],
    key?: string,
  ) => {
    return new Promise<AxiosResponse<T>>(async (resolve, reject) => {
      if (process.env.REACT_APP_CREATE && process.env.REACT_APP_CREATE === 'HOMOLOG') {
        console.log('request => ', method, url, data, headers, key);
      }
      axios
        .request<T>({
          method,
          url: this.makeUrl(url),
          headers: this.makeHeaders(headers),
          data: this.makeData(data),
        })
        .then(resp => {
          if (process.env.REACT_APP_CREATE && process.env.REACT_APP_CREATE === 'HOMOLOG') {
            console.log('resolve => ', method, url, data, headers, resp);
          }

          if (key) {
            db.set(key, resp.data);
          }

          resolve(resp);
        })
        .catch(err => {
          if (process.env.REACT_APP_CREATE && process.env.REACT_APP_CREATE === 'HOMOLOG') {
            console.log('reject => ', method, this.makeUrl(url), data, headers, err.response);
          }

          if (err.response && err.response.status === 401) {
            if (url && !(url.includes(`users/me`) || url.includes(`users/login`))) {
              toast.error('Sessão expirada/revogada, veja os logs');
              console.log('[SESSION]: status 401 >>', err.response);
              localStorage.setItem(
                `[SESSION-${new Date().getTime()}]`,
                JSON.stringify(err.response),
              );
            }
            store.dispatch(signOutRequest());
          }

          reject(err);
        });
    });
  };

  get = <T>(
    url: string,
    data?: AxiosRequestConfig['data'],
    headers?: AxiosRequestConfig['headers'],
    key?: string,
  ) => {
    return this.request<T>('get', url, data, headers, key);
  };

  post = <T>(
    url: string,
    data?: AxiosRequestConfig['data'],
    headers?: AxiosRequestConfig['headers'],
    key?: string,
  ) => {
    return this.request<T>('post', url, data, headers, key);
  };

  put = <T>(
    url: string,
    data?: AxiosRequestConfig['data'],
    headers?: AxiosRequestConfig['headers'],
    key?: string,
  ) => {
    return this.request<T>('put', url, data, headers, key);
  };

  delete = <T>(
    url: string,
    data?: AxiosRequestConfig['data'],
    headers?: AxiosRequestConfig['headers'],
    key?: string,
  ) => {
    return this.request<T>('delete', url, data, headers, key);
  };

  patch = <T>(
    url: string,
    data?: AxiosRequestConfig['data'],
    headers?: AxiosRequestConfig['headers'],
    key?: string,
  ) => {
    return this.request<T>('patch', url, data, headers, key);
  };
}

export default Request;
