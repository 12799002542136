export function cashtagsRequest() {
  return {
    type: '@cashtags/CASHTAGS_REQUEST',
  };
}

export function cashtagsSuccess(data) {
  return {
    type: '@cashtags/CASHTAGS_SUCCESS',
    payload: { data },
  };
}

export function cashtagsFailure() {
  return {
    type: '@cashtags/CASHTAGS_FAILURE',
  };
}
