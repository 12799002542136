export function signInRequest(login_id, password, saveLogin = false) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { login_id, password, saveLogin },
  };
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function signInSaveData(username, password) {
  return {
    type: '@auth/SIGN_IN_SAVEDATA',
    payload: { username, password },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function signOutRequest() {
  return {
    type: '@auth/SIGN_OUT_REQUEST',
  };
}
