import { combineReducers } from 'redux';

import auth from './auth/reducer';
import cashtags from './cashtags/reducer';
import hashtags from './hashtags/reducer';
import matrixWallet from './matrixWallet/reducer';
import posts from './posts/reducer';
import user from './user/reducer';

export default combineReducers({
  auth,
  user,
  posts,
  hashtags,
  cashtags,
  matrixWallet,
});
