import config from '../config/clients';
import Request from '../services/request';

class MatrixWalletClient {
  constructor() {
    this.request = new Request(config.url.matrixWallet);
  }

  getAllWallets = () => {
    return this.request.get('/wallet?admin=true');
  };

  getOneWallet = id => {
    return this.request.get(`/wallet/${id}`);
  };

  creatAllWallets = obj => {
    return this.request.post('/wallet', obj);
  };

  updateWallet = obj => {
    return this.request.put(`/wallet`, obj);
  };

  getHashFilePublic = id => {
    return this.request.get(`wallet/files/${id}/public`);
  };

  getWalletStocks = wallet_id => {
    return this.request.get(`/admin/walletstock/${wallet_id}`);
  };

  updateWalletStocks = stock => {
    return this.request.put(`/admin/walletstock`, stock);
  };

  getAllCurrencies = () => {
    return this.request.get(`/currency/all`);
  };

  getBenchmarks = () => {
    return this.request.get(`/admin/benchmarks`);
  };

  getExceptionalAssets = () => {
    return this.request.get(`/admin/exceptionalassets`);
  };

  uploadPdfWalletReport = file => {
    return this.#uploadFile(`/wallet/files/report/upload`, file);
  };

  uploadPdfStockReport = file => {
    return this.#uploadFile(`/admin/report/upload`, file);
  };

  #uploadFile = (url, file) => {
    const formData = new FormData();
    formData.append('file', file);
    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    return this.request.post(url, formData, headers);
  };
}

export default MatrixWalletClient;
