import config from '../config/clients';
import Request from '../services/request';

class MiningClient {
  constructor() {
    this.request = new Request(config.url.mining);
  }

  getCashtags = () => {
    return this.request.get(`miner/getcashtag`);
  };
}

export default MiningClient;
