import { all, put, takeLatest } from 'redux-saga/effects';

import {
  stockBufferSuccess,
  putAllCurrencies,
  putBenchmarks,
  putExceptionalAssets,
} from './actions';

import MatrixWallet from '../../../clients/matrixWallet';

export function* updateStockBuffer(data, type) {
  yield put(stockBufferSuccess(data, type));
}

export function* currencies() {
  const MatrixWalletClient = new MatrixWallet();
  const resp = yield MatrixWalletClient.getAllCurrencies();

  yield put(putAllCurrencies(resp.data));
}

export function* benchmarks() {
  const MatrixWalletClient = new MatrixWallet();
  const resp = yield MatrixWalletClient.getBenchmarks();

  const mapped = resp.data.map(bench => {
    return {
      id: bench.id,
      label: bench.ticker,
      value: bench.stock_id,
    };
  });

  mapped.unshift({
    id: 0,
    label: '',
    value: 0,
  });

  yield put(putBenchmarks(mapped));
}

export function* exceptionalAssets() {
  const MatrixWalletClient = new MatrixWallet();
  const resp = yield MatrixWalletClient.getExceptionalAssets();
  yield put(putExceptionalAssets(resp.data));
}

export default all([
  takeLatest('@auth/SIGN_IN_SUCCESS', updateStockBuffer),
  takeLatest('@stockBuffer/STOCKBUFFER_REQUEST', updateStockBuffer),
  takeLatest('@stockbuffer/MATRIX_WALLET_GET_CURRENCIES', currencies),
  takeLatest('@stockbuffer/MATRIX_WALLET_GET_BENCHMARK', benchmarks),
  takeLatest('@stockbuffer/MATRIX_WALLET_GET_EXCEPTIONAL_ASSETS', exceptionalAssets),
]);
