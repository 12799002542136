export default {
  colors: {
    white: '#fff',
    black: '#000',
    blue: '#4567e9',
    blueLight: '#4364e8',
    positive: '#28d499',
    danger: '#EF3957',
    yellow: '#FFA500',
    blueBorder: '#3856cb',
    contentColor: '#fff',
    contentColorIdeas: '#f0f2f5',
    contrast: '#000',
    fontBase: '#1e2b3d',
    outline: '#e0e2e7',
    darkGrey: '#838b96',
    masked: '#e4ebfc',
    postInfo: '#74788c',
    postAuthor: '#2a3749',
    postLine: '#e6e8eb',
    postBlocked: '#e2dede',
    postUrgente: '#e92b4605',
    postUrgenteBlocked: '#e92b4645',
    postUrgenteBorder: '#e92b46',
    postCollab: '#ff952d05',
    postCollabBlocked: '#ff952d45',
    postCollabBorder: '#ff952d',
    postImportante: '#28d49905',
    postImportanteBlocked: '#28d49945',
    postImportanteBorder: '#28d499',
    newsBanner: '#e4ebfc',
    darkText: '#2a3749',
    optionsColor: '#fff',
    optionsBg: '#6582ff',
    hoverBackground: '#f1f2f6',
    colorActiveBase: '#6582ff',
  },
};
