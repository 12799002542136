export function hashtagsRequest() {
  return {
    type: '@hashtags/HASHTAGS_REQUEST',
  };
}

export function hashtagsSuccess(data) {
  return {
    type: '@hashtags/HASHTAGS_SUCCESS',
    payload: { data },
  };
}

export function hashtagsFailure() {
  return {
    type: '@hashtags/HASHTAGS_FAILURE',
  };
}
