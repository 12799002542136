import produce from 'immer';

const INITIAL_STATE = {
  timeline: {
    badge: 0,
    order: [],
    posts: {},
    loading: false,
  },
  draft: {
    order: [],
    posts: {},
    loading: false,
  },
  highlights: {
    order: [],
    posts: {},
  },
  users: [],
};

export default function posts(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@posts/NEWS_RECEIVED_POSTS_REQUEST': {
        draft.timeline.loading = true;
        break;
      }

      case '@posts/NEWS_DRAFT_POSTS_REQUEST': {
        draft.draft.loading = true;
        break;
      }

      case '@posts/NEWS_RECEIVED_POSTS_SUCCESS': {
        if (action.payload.page === 0) {
          draft.timeline = {
            badge: 0,
            order: [...action.payload.data.order],
            posts: { ...action.payload.data.posts },
            loading: false,
          };

          let users = [];
          // if (action.payload.users > 0) {
          action.payload.users.map(i => {
            if (!state.users.some(user => user.id === i.id)) {
              users.push(i);
            }
            return i;
          });

          draft.users = [...state.users, ...users];
          // draft.users = [...action.payload.users];
          break;
        }
        let order = [];
        action.payload.data.order.map(i => {
          if (!state.timeline.order.includes(i)) {
            order.push(i);
          }
          return i;
        });
        let users = [];
        // if (action.payload.users > 0) {
        action.payload.users.map(i => {
          if (!state.users.some(user => user.id === i.id)) {
            users.push(i);
          }
          return i;
        });
        // }
        draft.timeline = {
          order: [...state.timeline.order, ...order],
          posts: {
            ...state.timeline.posts,
            ...action.payload.data.posts,
          },
          loading: false,
        };
        draft.users = [...state.users, ...users];
        break;
      }

      case '@posts/NEWS_DRAFT_POSTS_SUCCESS': {
        if (action.payload.page === 0) {
          draft.draft = {
            order: [...action.payload.data.order],
            posts: { ...action.payload.data.posts },
            loading: false,
          };
          let users = [];
          // if (action.payload.users > 0) {
          action.payload.users.map(i => {
            if (!state.users.some(user => user.id === i.id)) {
              users.push(i);
            }
            return i;
          });

          draft.users = [...state.users, ...users];
          break;
        }

        let order = [];
        action.payload.data.order.map(i => {
          if (!state.draft.order.includes(i)) {
            order.push(i);
          }
          return i;
        });
        let users = [];
        // if (action.payload.users > 0) {
        action.payload.users.map(i => {
          if (!state.users.some(user => user.id === i.id)) {
            users.push(i);
          }
          return i;
        });
        // }
        draft.draft = {
          order: [...state.draft.order, ...order],
          posts: {
            ...state.draft.posts,
            ...action.payload.data.posts,
          },
          loading: false,
        };
        draft.users = [...state.users, ...users];
        break;
      }

      case '@highlights/NEWS_RECEIVED_HIGHLIGHTS_SUCCESS': {
        let users = [];
        // if (action.payload.users > 0) {
        action.payload.users.map(i => {
          if (!state.users.some(user => user.id === i.id)) {
            users.push(i);
          }
          return i;
        });
        // }

        draft.highlights = {
          order: [...action.payload.data.order],
          posts: { ...action.payload.data.posts },
        };
        draft.users = [...state.users, ...users];

        break;
      }

      case '@posts/NEWS_EVENT_MOVER_CREATED': {
        if (!draft.timeline.order.includes(action.payload.data.id)) {
          draft.timeline = {
            badge: state.timeline.badge + 1,
            order: [action.payload.data.id, ...state.timeline.order],
            posts: {
              ...state.timeline.posts,
              [action.payload.data.id]: action.payload.data,
            },
          };
        }
        break;
      }

      case '@posts/NEWS_EVENT_MOVER_CREATED_DRAFT': {
        if (!draft.draft.order.includes(action.payload.data.id)) {
          draft.draft = {
            order: [action.payload.data.id, ...state.draft.order],
            posts: {
              ...state.draft.posts,
              [action.payload.data.id]: action.payload.data,
            },
          };
        }
        break;
      }

      case '@posts/NEWS_EVENT_MOVER_UPDATED': {
        draft.timeline = {
          badge: state.timeline.badge,
          loading: state.timeline.loading,
          order: [...state.timeline.order],
          posts: {
            ...state.timeline.posts,
            [action.payload.data.id]: action.payload.data,
          },
        };
        break;
      }

      case '@posts/NEWS_EVENT_MOVER_UPDATED_DRAFT': {
        draft.draft = {
          loading: state.draft.loading,
          order: [...state.draft.order],
          posts: {
            ...state.draft.posts,
            [action.payload.data.id]: action.payload.data,
          },
        };
        break;
      }

      case '@posts/NEWS_EVENT_MOVER_DELETED': {
        draft.timeline = {
          order: [...state.timeline.order.filter(id => action.payload.data.id !== id)],
          posts: {
            ...state.timeline.posts,
            [action.payload.data.id]: action.payload.data,
          },
        };
        break;
      }

      case '@posts/NEWS_EVENT_MOVER_DELETED_DRAFT': {
        draft.draft = {
          order: [...state.draft.order.filter(id => action.payload.data.id !== id)],
          posts: {
            ...state.draft.posts,
            [action.payload.data.id]: action.payload.data,
          },
        };
        break;
      }

      case '@posts/NEWS_EVENT_BADGE_RESET': {
        draft.timeline = {
          badge: 0,
        };
        break;
      }

      case '@posts/NEWS_RECEIVED_POSTS_FAILURE': {
        draft.timeline = {
          loading: false,
          order: state.timeline.order,
          posts: state.timeline.posts,
        };
        break;
      }

      case '@posts/NEWS_DRAFT_POSTS_FAILURE': {
        draft.draft = {
          loading: false,
          order: state.draft.order,
          posts: state.draft.posts,
        };
        break;
      }

      case '@highlights/NEWS_RECEIVED_POSTS_FAILURE': {
        draft.highlights = {
          order: state.highlights.order,
          posts: state.highlights.posts,
        };
        break;
      }

      case '@auth/SIGN_OUT': {
        draft = INITIAL_STATE;
        break;
      }

      case '@auth/SIGN_OUT_REQUEST': {
        draft = INITIAL_STATE;
        break;
      }

      default:
    }
  });
}
