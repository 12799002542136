import React, { useEffect } from 'react';

import { Provider } from 'react-redux';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import './config/ReactotronConfig';

import 'react-toastify/dist/ReactToastify.css';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import './App.scss';
import server from './clients/server';
import { store, persistor } from './store';
import Emitter from './utils/emitter';
import { isAuthenticated } from './utils/validAuth';

import lightTheme from '../src/themes/light';

const loading = () => <div className='animated fadeIn pt-3 text-center'>Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

export default function App() {
  let themeColor = lightTheme;

  useEffect(() => {
    server.initMe().finally(() => Emitter.emit('RELOAD_NAV'));
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={themeColor}>
          <HashRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route
                  exact
                  path='/login'
                  name='Login Page'
                  render={props => <Login {...props} />}
                />
                <Route
                  exact
                  path='/register'
                  name='Register Page'
                  render={props => <Register {...props} />}
                />
                <Route exact path='/404' name='Page 404' render={props => <Page404 {...props} />} />
                <Route exact path='/500' name='Page 500' render={props => <Page500 {...props} />} />
                <Route
                  path='/'
                  name='Home'
                  render={props =>
                    isAuthenticated() ? (
                      <DefaultLayout {...props} />
                    ) : (
                      <Redirect to={{ pathname: '/login' }} />
                    )
                  }
                />
              </Switch>
              <ToastContainer />
            </React.Suspense>
          </HashRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}
