export function highlightsReceivedRequest() {
  return {
    type: '@highlights/NEWS_RECEIVED_HIGHLIGHTS_REQUEST',
  };
}

export function postReceivedRequest(page = 0, limit = 30) {
  return {
    type: '@posts/NEWS_RECEIVED_POSTS_REQUEST',
    payload: { page, limit },
  };
}

export function postDraftRequest(page = 0, limit = 30) {
  return {
    type: '@posts/NEWS_DRAFT_POSTS_REQUEST',
    payload: { page, limit },
  };
}

export function postReceivedSuccess({ data, users, page }) {
  return {
    type: '@posts/NEWS_RECEIVED_POSTS_SUCCESS',
    payload: { data, page, users },
  };
}

export function postDraftSuccess({ data, users, page }) {
  return {
    type: '@posts/NEWS_DRAFT_POSTS_SUCCESS',
    payload: { data, page, users },
  };
}

export function highlightsReceivedSuccess({ data, users }) {
  return {
    type: '@highlights/NEWS_RECEIVED_HIGHLIGHTS_SUCCESS',
    payload: { data, users },
  };
}

export function postMoverCreated(data) {
  return {
    type: '@posts/NEWS_EVENT_MOVER_CREATED',
    payload: { data },
  };
}

export function postDraftCreated(data) {
  return {
    type: '@posts/NEWS_EVENT_MOVER_CREATED_DRAFT',
    payload: { data },
  };
}

export function postMoverUpdated(data) {
  return {
    type: '@posts/NEWS_EVENT_MOVER_UPDATED',
    payload: { data },
  };
}

export function postDraftUpdated(data) {
  return {
    type: '@posts/NEWS_EVENT_MOVER_UPDATED_DRAFT',
    payload: { data },
  };
}

export function postMoverDeleted(data) {
  return {
    type: '@posts/NEWS_EVENT_MOVER_DELETED',
    payload: { data },
  };
}

export function postDraftDeleted(data) {
  return {
    type: '@posts/NEWS_EVENT_MOVER_DELETED_DRAFT',
    payload: { data },
  };
}

export function postsReceivedFailure() {
  return {
    type: '@posts/NEWS_RECEIVED_POSTS_FAILURE',
  };
}

export function postsDraftFailure() {
  return {
    type: '@posts/NEWS_DRAFT_POSTS_FAILURE',
  };
}

export function highlightsReceivedFailure() {
  return {
    type: '@highlights/NEWS_RECEIVED_POSTS_FAILURE',
  };
}
